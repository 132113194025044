export const setUserEmailToCookies = (email: string) => {
  const date = new Date()
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  const domain = `domain=.${
    window.location.hostname.includes(import.meta.env.VITE_GRADE_DOMAIN)
      ? import.meta.env.VITE_GRADE_DOMAIN
      : import.meta.env.VITE_CAMBRIDGE_DOMAIN
  }`

  document.cookie = `email=${email}; ${expires}; ${domain}`
}

export const deleteUserEmailFromCookies = () => {
  const domain = `domain=.${
    window.location.hostname.includes(import.meta.env.VITE_GRADE_DOMAIN)
      ? import.meta.env.VITE_GRADE_DOMAIN
      : import.meta.env.VITE_CAMBRIDGE_DOMAIN
  }`

  document.cookie = `email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${domain}`
}

export const setUserNameToCookies = (name: string) => {
  const date = new Date()
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  const domain = `domain=.${
    window.location.hostname.includes(import.meta.env.VITE_GRADE_DOMAIN)
      ? import.meta.env.VITE_GRADE_DOMAIN
      : import.meta.env.VITE_CAMBRIDGE_DOMAIN
  }`

  document.cookie = `auth_user_name=${name}; ${expires}; ${domain}`
}

export const deleteUserNameFromCookies = () => {
  const domain = `domain=.${
    window.location.hostname.includes(import.meta.env.VITE_GRADE_DOMAIN)
      ? import.meta.env.VITE_GRADE_DOMAIN
      : import.meta.env.VITE_CAMBRIDGE_DOMAIN
  }`

  document.cookie = `auth_user_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${domain}`
}

export const getReferrerFromCookies = () => {
  return (
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('referrer='))
      ?.split('=')[1] || ''
  )
}
export const deleteReferrerFromCookies = () => {
  document.cookie = `referrer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}
