export function useBodyScrollLock() {
  const bodyElement: HTMLBodyElement | null = document.querySelector('body')

  function lockScroll() {
    if (bodyElement) {
      bodyElement.classList.add('overflow-hidden')
    }
  }

  function unlockScroll() {
    if (bodyElement) {
      bodyElement.classList.remove('overflow-hidden')
    }
  }

  return {
    lockScroll,
    unlockScroll
  }
}
