import type { SubstitutionStatus } from '@/entities/Substitution/types'
import type { Avatar } from '@/shared/types/Avatar'
import type { SimpleDateTime } from '@/shared/types/Date'
import type { File } from '@/shared/types/File'
import type { Pagination } from '@/shared/types/Pagination'

export enum SubstitutionFilterType {
  All = '',
  MySubstitutions = 'is_mine',
  OtherTutorsSubstitutions = 'is_not_mine'
}

export enum SubstitutionType {
  Tkt = 'tkt',
  Tylt = 'tylt'
}

type SubstitutionGroup = {
  id: number
  name: string
  tutor: Avatar
}

export type SubstitutionShortInfo = {
  id: number
  status: SubstitutionStatus
  is_mine: boolean
  i_substitute: boolean
  lesson_date: SimpleDateTime
  group: SubstitutionGroup
  substitution_tutor: Avatar | null
}

export type SubstitutionResponse = {
  data: {
    data: SubstitutionShortInfo[]
    meta: {
      include: string[]
      custom: string[]
      pagination: Pagination
    }
  }
}

export type SubstitutionInfoDetail = {
  id: number
  lesson_date: SimpleDateTime
  group: SubstitutionGroup
  description: string
  files: File[]
}

export type SubstitutionDataResponse = {
  data: {
    data: SubstitutionInfoDetail
  }
}

// Available teachers
export type AvailableTeachersData = Array<
  Avatar & {
    email: string
    telegram: string | null
  }
>

export type AvailableTeachersResponse = {
  data: {
    data: AvailableTeachersData
  }
}

export type AvailableTeachersDataQuery = {
  startDateTime: string
  endDateTime: string
}
