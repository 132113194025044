import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { SubstitutionType } from '@/modules/tutor/Substitutions/List/widgets/types'

export default [
  {
    path: '/tutor',
    name: 'tutor-space',
    meta: {
      space: 'tutor'
    },
    component: () => import('@/entities/Layout/ui/SuspenseComponent.vue'),
    redirect: { name: 'tutor-tkt-groups' },
    children: [
      {
        path: 'tkt-groups',
        name: 'tutor-tkt-groups',
        component: () => import('@/modules/tutor/TKTGroups/List/pages/CardsListView.vue')
      },
      {
        path: 'tkt-groups/:id',
        name: 'tutor-tkt-group',
        component: () => import('@/modules/tutor/TKTGroups/Layout/widgets/ui/GroupLayout.vue'),
        redirect: { name: 'tutor-tkt-group-general' },
        children: [
          {
            path: 'general',
            name: 'tutor-tkt-group-general',
            component: () => import('@/modules/tutor/TKTGroups/General/pages/GeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'tutor-tkt-group-schedule',
            component: () =>
              import('@/modules/tutor/TKTGroups/Schedule/List/pages/ScheduleView.vue')
          },
          {
            path: 'schedule/:lessonId',
            name: 'tutor-tkt-group-schedule-lesson',
            component: () =>
              import('@/modules/tutor/TKTGroups/Schedule/Layout/widgets/ui/LessonLayout.vue'),
            redirect: { name: 'tutor-tkt-group-schedule-lesson-summary' },
            children: [
              {
                path: 'lesson-summary',
                name: 'tutor-tkt-group-schedule-lesson-summary',
                component: () =>
                  import('@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonSummaryView.vue')
              },
              {
                path: 'lesson-notes',
                name: 'tutor-tkt-group-schedule-lesson-notes',
                component: () =>
                  import('@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonNotesView.vue')
              },
              {
                path: 'lesson-meeting',
                name: 'tutor-tkt-group-schedule-lesson-meeting',
                component: () =>
                  import('@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonMeetingView.vue')
              },
              {
                path: 'lesson-substitution',
                name: 'tutor-tkt-group-schedule-lesson-substitution',
                component: () =>
                  import(
                    '@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonSubstitutionView.vue'
                  )
              }
            ]
          },
          {
            path: 'trainees',
            name: 'tutor-tkt-group-trainees',
            component: () => import('@/modules/tutor/TKTGroups/Trainees/pages/TraineesView.vue')
          },
          {
            path: 'course-summary',
            name: 'tutor-tkt-group-course-summary',
            component: () =>
              import('@/modules/tutor/TKTGroups/CourseSummary/pages/CourseSummaryView.vue')
          }
        ]
      },
      {
        path: 'trainees',
        name: 'tutor-trainees',
        component: () => import('@/modules/tutor/Trainees/pages/TraineesListView.vue')
      },
      {
        path: 'substitutions/:type?',
        name: 'tutor-substitutions',
        component: () =>
          import('@/modules/tutor/Substitutions/List/pages/SubstitutionsListView.vue'),
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => {
          if (!to.params.type) {
            next({ name: 'tutor-substitutions', params: { type: SubstitutionType.Tkt } })
          } else {
            next()
          }
        }
      },
      {
        path: 'tkt-groups/substitutions/:id',
        name: 'tutor-substitution-group',
        component: () => import('@/modules/tutor/Substitutions/Layout/widgets/ui/GroupLayout.vue'),
        redirect: { name: 'tutor-substitution-group-general' },
        children: [
          {
            path: 'general',
            name: 'tutor-substitution-group-general',
            component: () =>
              import('@/modules/tutor/Substitutions/General/pages/SubstitutionGeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'tutor-substitution-group-schedule',
            component: () =>
              import(
                '@/modules/tutor/Substitutions/Schedule/List/pages/SubstitutionScheduleView.vue'
              )
          },
          {
            path: 'schedule/lesson/:lessonId',
            name: 'tutor-substitution-group-schedule-lesson',
            component: () =>
              import('@/modules/tutor/Substitutions/Schedule/Layout/widgets/ui/LessonLayout.vue'),
            redirect: { name: 'tutor-substitution-group-lesson-summary' },
            children: [
              {
                path: 'lesson-summary',
                name: 'tutor-substitution-group-lesson-summary',
                component: () =>
                  import(
                    '@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonSummaryView.vue'
                  )
              },
              {
                path: 'lesson-notes',
                name: 'tutor-substitution-group-lesson-notes',
                component: () =>
                  import('@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonNotesView.vue')
              },
              {
                path: 'lesson-meeting',
                name: 'tutor-substitution-group-lesson-meeting',
                component: () =>
                  import(
                    '@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonMeetingView.vue'
                  )
              },
              {
                path: 'lesson-substitution',
                name: 'tutor-substitution-group-lesson-substitution',
                component: () =>
                  import(
                    '@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonSubstitutionView.vue'
                  )
              }
            ]
          },
          {
            path: 'trainees',
            name: 'tutor-substitution-group-trainees',
            component: () =>
              import('@/modules/tutor/Substitutions/Trainees/pages/SubstitutionTraineesView.vue')
          }
        ]
      },
      {
        path: 'tylt-groups',
        name: 'tutor-tylt-groups',
        component: () => import('@/modules/tutor/TYLT/Groups/List/pages/CardsListView.vue')
      },
      {
        path: 'tylt-groups/:id',
        component: () => import('@/modules/tutor/TYLT/Groups/Layout/widgets/ui/GroupLayout.vue'),
        children: [
          {
            path: 'general',
            name: 'tutor-tylt-group-general',
            component: () => import('@/modules/tutor/TYLT/Groups/General/pages/GeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'tutor-tylt-group-schedule',
            component: () =>
              import('@/modules/tutor/TYLT/Groups/Schedule/List/pages/ScheduleView.vue')
          },
          {
            path: 'schedule/:lessonId',
            component: () =>
              import('@/modules/tutor/TYLT/Groups/Schedule/Layout/widgets/ui/LessonLayout.vue'),
            children: [
              {
                path: 'lesson-summary',
                name: 'tutor-tylt-group-schedule-lesson-summary',
                component: () =>
                  import('@/modules/tutor/TYLT/Groups/Schedule/Lesson/pages/LessonSummaryView.vue')
              },
              {
                path: 'lesson-notes',
                name: 'tutor-tylt-group-schedule-lesson-notes',
                component: () =>
                  import('@/modules/tutor/TYLT/Groups/Schedule/Lesson/pages/LessonNotesView.vue')
              },
              {
                path: 'lesson-meeting',
                name: 'tutor-tylt-group-schedule-lesson-meeting',
                component: () =>
                  import('@/modules/tutor/TYLT/Groups/Schedule/Lesson/pages/LessonMeetingView.vue')
              }
            ]
          },
          {
            path: 'course-tasks',
            redirect: { name: 'tutor-tylt-group-course-tasks' },
            children: [
              {
                path: '',
                name: 'tutor-tylt-group-course-tasks',
                component: () =>
                  import('@/modules/tutor/TYLT/Groups/CourseTasks/pages/CourseTasksView.vue')
              },
              {
                path: 'gu',
                redirect: { name: 'tutor-tylt-group-course-tasks' },
                children: [
                  {
                    path: ':taskId',
                    children: [
                      {
                        path: 'table-students',
                        name: 'tutor-tylt-group-course-tasks-gu-table',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/GUTask/TraineesTableGUTask.vue'
                          )
                      }
                    ]
                  }
                ]
              },
              {
                path: 'ot-yl',
                redirect: { name: 'tutor-tylt-group-course-tasks' },
                children: [
                  {
                    path: 'create',
                    name: 'tutor-tylt-group-course-tasks-ot-ly-create',
                    component: () =>
                      import(
                        '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskYL/CreateObservationTaskYLView.vue'
                      )
                  },
                  {
                    path: ':taskId',
                    children: [
                      {
                        path: 'table-students',
                        name: 'tutor-tylt-group-course-tasks-ot-ly-table',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskYL/TraineesTableObservationTaskYLView.vue'
                          )
                      },
                      {
                        path: 'edit-general',
                        name: 'tutor-tylt-group-course-tasks-ot-ly-edit-general',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskYL/EditGeneralObservationTaskYLView.vue'
                          )
                      },
                      {
                        path: 'table-students/:studentId',
                        redirect: { name: 'tutor-tylt-group-course-tasks-ot-ly-trainee' },
                        children: [
                          {
                            path: 'edit-individual',
                            name: 'tutor-tylt-group-course-tasks-ot-ly-edit-individual',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskYL/EditIndividualObservationTaskYLView.vue'
                              )
                          },
                          {
                            path: 'review',
                            name: 'tutor-tylt-group-course-tasks-ot-ly-review',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskYL/ReviewIndividualObservationTaskYLView.vue'
                              )
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'ot-teens',
                redirect: { name: 'tutor-tylt-group-course-tasks' },
                children: [
                  {
                    path: 'create',
                    name: 'tutor-tylt-group-course-tasks-ot-teens-create',
                    component: () =>
                      import(
                        '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskTeens/CreateObservationTaskTeensView.vue'
                      )
                  },
                  {
                    path: ':taskId',
                    children: [
                      {
                        path: 'table-students',
                        name: 'tutor-tylt-group-course-tasks-ot-teens-table',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskTeens/TraineesTableObservationTaskTeensView.vue'
                          )
                      },
                      {
                        path: 'edit-general',
                        name: 'tutor-tylt-group-course-tasks-ot-teens-edit-general',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskTeens/EditGeneralObservationTaskTeensView.vue'
                          )
                      },
                      {
                        path: 'table-students/:studentId',
                        redirect: { name: 'tutor-tylt-group-course-tasks-ot-teens-trainee' },
                        children: [
                          {
                            path: 'edit-individual',
                            name: 'tutor-tylt-group-course-tasks-ot-teens-edit-individual',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskTeens/EditIndividualObservationTaskTeensView.vue'
                              )
                          },
                          {
                            path: 'review',
                            name: 'tutor-tylt-group-course-tasks-ot-teens-review',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/ObservationTaskTeens/ReviewIndividualObservationTaskTeensView.vue'
                              )
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'final-test',
                redirect: { name: 'tutor-tylt-group-course-tasks' },
                children: [
                  {
                    path: 'create',
                    name: 'tutor-tylt-group-course-tasks-final-test-create',
                    component: () =>
                      import(
                        '@/modules/tutor/TYLT/Groups/CourseTasks/pages/FinalTest/CreateFinalTestView.vue'
                      )
                  },
                  {
                    path: ':taskId',
                    children: [
                      {
                        path: 'table-students',
                        name: 'tutor-tylt-group-course-tasks-final-test-table',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/FinalTest/TraineesTableFinalTestView.vue'
                          )
                      },
                      {
                        path: 'edit-general',
                        name: 'tutor-tylt-group-course-tasks-final-test-edit-general',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/FinalTest/EditGeneralFinalTestView.vue'
                          )
                      },
                      {
                        path: 'table-students/:studentId',
                        redirect: { name: 'tutor-tylt-group-course-tasks-final-test-trainee' },
                        children: [
                          {
                            path: 'edit-individual',
                            name: 'tutor-tylt-group-course-tasks-final-test-edit-individual',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/FinalTest/EditIndividualFinalTestView.vue'
                              )
                          },
                          {
                            path: 'review',
                            name: 'tutor-tylt-group-course-tasks-final-test-review',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/FinalTest/ReviewIndividualFinalTestView.vue'
                              )
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: 'practice',
                redirect: { name: 'tutor-tylt-group-course-tasks' },
                children: [
                  {
                    path: 'create',
                    name: 'tutor-tylt-group-course-tasks-practice-create',
                    component: () =>
                      import(
                        '@/modules/tutor/TYLT/Groups/CourseTasks/pages/PracticeTask/CreatePracticeTaskView.vue'
                      )
                  },
                  {
                    path: 'tasks/:taskId',
                    children: [
                      {
                        path: 'table-students',
                        name: 'tutor-tylt-group-course-tasks-practice-table',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/PracticeTask/TraineesTablePracticeTaskView.vue'
                          )
                      },
                      {
                        path: 'edit-general',
                        name: 'tutor-tylt-group-course-tasks-practice-edit-general',
                        component: () =>
                          import(
                            '@/modules/tutor/TYLT/Groups/CourseTasks/pages/PracticeTask/EditGeneralPracticeTaskView.vue'
                          )
                      },
                      {
                        path: 'table-students/:studentId',
                        redirect: { name: 'tutor-tylt-group-course-tasks-practice-trainee' },
                        children: [
                          {
                            path: 'edit-individual',
                            name: 'tutor-tylt-group-course-tasks-practice-edit-individual',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/PracticeTask/EditIndividualPracticeTaskView.vue'
                              )
                          },
                          {
                            path: 'review',
                            name: 'tutor-tylt-group-course-tasks-practice-review',
                            component: () =>
                              import(
                                '@/modules/tutor/TYLT/Groups/CourseTasks/pages/PracticeTask/ReviewIndividualPracticeTaskView.vue'
                              )
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'trainees',
            name: 'tutor-tylt-group-trainees',
            component: () => import('@/modules/tutor/TYLT/Groups/Trainees/pages/TraineesView.vue')
          },
          {
            path: 'course-summary',
            name: 'tutor-tylt-group-course-summary',
            component: () =>
              import('@/modules/tutor/TYLT/Groups/CourseSummary/pages/CourseSummaryView.vue')
          }
        ]
      },
      {
        path: 'tylt-groups/substitutions/:id',
        component: () =>
          import('@/modules/tutor/TYLT/Substitutions/Layout/widgets/ui/GroupLayout.vue'),
        children: [
          {
            path: 'general',
            name: 'tutor-tylt-substitution-group-general',
            component: () =>
              import('@/modules/tutor/TYLT/Substitutions/General/pages/SubstitutionGeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'tutor-tylt-substitution-group-schedule',
            component: () =>
              import(
                '@/modules/tutor/TYLT/Substitutions/Schedule/List/pages/SubstitutionScheduleView.vue'
              )
          },
          {
            path: 'schedule/lesson/:lessonId',
            component: () =>
              import(
                '@/modules/tutor/TYLT/Substitutions/Schedule/Layout/widgets/ui/LessonLayout.vue'
              ),
            children: [
              {
                path: 'lesson-summary',
                name: 'tutor-tylt-substitution-group-lesson-summary',
                component: () =>
                  import(
                    '@/modules/tutor/TYLT/Substitutions/Schedule/Lesson/pages/LessonSummaryView.vue'
                  )
              },
              {
                path: 'lesson-notes',
                name: 'tutor-tylt-substitution-group-lesson-notes',
                component: () =>
                  import(
                    '@/modules/tutor/TYLT/Substitutions/Schedule/Lesson/pages/LessonNotesView.vue'
                  )
              },
              {
                path: 'lesson-meeting',
                name: 'tutor-tylt-substitution-group-lesson-meeting',
                component: () =>
                  import(
                    '@/modules/tutor/TYLT/Substitutions/Schedule/Lesson/pages/LessonMeetingView.vue'
                  )
              },
              {
                path: 'lesson-substitution',
                name: 'tutor-tylt-substitution-group-lesson-substitution',
                component: () =>
                  import(
                    '@/modules/tutor/TYLT/Substitutions/Schedule/Lesson/pages/LessonSubstitutionView.vue'
                  )
              }
            ]
          },
          {
            path: 'trainees',
            name: 'tutor-tylt-substitution-group-trainees',
            component: () =>
              import(
                '@/modules/tutor/TYLT/Substitutions/Trainees/pages/SubstitutionTraineesView.vue'
              )
          }
        ]
      }
    ]
  }
]
